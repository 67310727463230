import React from 'react';

import Layout from '../components/furniture/Layout/Layout';
import generateTitle from '../utils/generateTitle';

const LoggedOutPage = ({ location }) => {
  const pageTitle = generateTitle('Logged Out');

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <h1>Logged Out</h1>
    </Layout>
  );
};

export default LoggedOutPage;
